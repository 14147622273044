import { createGlobalStyle } from "styled-components";
import bg from "../asstes/bg.png";

const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    body {
        width: 100vw;
        height: 100vh;
        background: url(${bg}) no-repeat center center;
        background-size: cover; 
        font-family: -apple-system, "Roboto", BlinkMacSystemFont, "SF Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
        overflow-x: hidden;
        transition: background 0.3s ease; /* Suaviza a transição ao mudar o tamanho da tela */
    }

    /* Responsividade */
    @media (max-width: 1200px) {
        body {
            background-size: contain;
        }
    }

    @media (max-width: 1024px) {
        body {
            background-size: cover;
        }
    }

    @media (max-width: 768px) {
        body {
            background-position: center;
            background-size: auto 100%; /* Adapta a altura da imagem */
        }
    }

    @media (max-width: 480px) {
        body {
            background-size: 100% auto;
            background-position: top center;
        }
    }

    @media (max-width: 320px) {
        body {
            background-size: cover;
            background-position: center;
        }
    }
`;

export default GlobalStyle; 

export const FontFamily = "-apple-system, Helvetica, Arial, sans-serif";
