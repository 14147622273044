import GlobalStyle from './styles/global';
import React from 'react';

function App() {
  return (
    <div className="App">
      <header className="App">
        <GlobalStyle>
        </GlobalStyle>
      </header>
    </div>
  );
}

export default App;
